import { gql } from '@apollo/client'

import { UserRole } from '../../../_shared/types'
import { OFFER_FRAGMENT } from '.'

export const POST_OFFER = gql`
  query PostOffer($offerID: ID!, $postID: ID!) {
    postOffer(input: { offerID: $offerID, postID: $postID }) {
      id
      postID
      amount {
        value
        currency
      }
      message
      user {
        id
      }
      workspace {
        id
        displayName
        workspacename
        logo
        isVerified
        workspaceVerificationType
        company {
          id
          companyName
          mcNumber
          phoneNumber
        }
      }
    }
  }
`

export interface PostOfferReturnType {
  amount: {
    currency: string
    value: string
  }
  created: string
  emptyAtPreviousReceiverTime: string

  id: string
  location: string
  mcNumber: string
  message: string
  offerID?: string
  offerStatus?: string
  postID: string
  user: {
    id: string
    name: string
    photo: string
    primaryMembership: {
      accessType: string | null
      isPrimary: boolean | null
      workspace: { displayName: string; isVerified: boolean; logo: string | null }
    } | null
    role: UserRole | null
    username: string
  }
}

export const POST_OFFERS = gql`
  query PostOffers($id: ID!) {
    postOffers(input: { id: $id }) {
      ...OfferFragment
    }
  }
  ${OFFER_FRAGMENT}
`
/**
 * GraphQL query to fetch all of the fmcsa changes and associations
 * for a dot number
 */
export const COMPANY_INTELLIGENCE = gql`
  query ($dotNumber: String!) {
    companyIntelligence(input: { dotNumber: $dotNumber }) {
      fmcsaChanges {
        field
        alert
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
        modifications {
          value
          date
        }
      }
      associations {
        field
        value
        alert
        firstObservedDate
        isCurrentlyOnFileWithFMCSA
        associatedCompanies {
          id
          companyDetails {
            mcNumber
            legalName {
              latestValue {
                value
                date
              }
              oldestValue {
                value
                date
              }
            }
          }
        }
      }
    }
  }
`
