import { ROUTES } from '../../_routes/routes-list'

export default {
  CREATE_NEW_ACCOUNT: 'create_new_account',
  FILE_SIZE_EXCEEDED: '😱 Whoa! Please upload a picture smaller than 16 MB.',
  INVITE_DISPATCHER: 'invite_dispatcher',
  INVITE_USER_TO_CREATE_ACCOUNT: 'invite_user_to_create_account',
  JOIN_ACCOUNT: 'join_account',
  JOIN_INVITED_USER: 'join_invited_user',
  ONBOARD_USER: 'onboard_user',
  PAGE_TITLE: [
    { key: ROUTES.CONTAINERS, title: 'Container Tracking' },
    { key: ROUTES.LOGIN, title: 'Login' },
    { key: ROUTES.VERIFY, title: 'Verify' },
    { key: ROUTES.SIGNUP, title: 'Sign Up' },

    { key: ROUTES.ONBOARDING, title: 'Onboarding' },

    { key: ROUTES.PROFILE, title: 'Profile' },
    { key: ROUTES.ASSIGNED_TO_ME, title: 'Assigned to Me' },
    { key: ROUTES.INBOX, title: 'Inbox' },
    { key: ROUTES.MY_POSTS, title: 'My posts' },
    { key: ROUTES.HOME_PAGE, title: 'Home / Chaine' },

    { key: ROUTES.SHIPMENTS, title: 'Load Tracking' },

    { key: ROUTES.BILLING, title: 'Billing' },
    { key: ROUTES.BILLING_PLAN, title: 'Billing - Plan' },
    { key: ROUTES.BILLING_INVOICE, title: 'Billing - Invoice' },
    { key: ROUTES.BILLING_CARD_DETAILS, title: 'Billing - Card Details' },
    { key: ROUTES.BILLING_CANCEL_PLAN, title: 'Billing - Cancel Plan' },

    { key: ROUTES.INVITE_USER, title: 'Invite' },
    { key: ROUTES.VERIFY_INVITATION, title: 'Verify' },
    { key: ROUTES.MEMBERS, title: 'Members' },

    { key: ROUTES.COMPANIES, title: 'Companies' },
    { key: ROUTES.CREATE_ACCOUNT, title: 'Create Account' },

    { key: ROUTES.TERMS, title: 'Terms of Service' },
    { key: ROUTES.PRIVACY, title: 'Privacy Policy' },
    { key: ROUTES.TESTIMONIALS, title: 'Testimonials' },
    { key: ROUTES.REQUEST_ACCESS, title: 'Request Access' },
    { key: ROUTES.PRODUCT_INTEGRATION, title: 'Product - Integration' },
    { key: ROUTES.PRODUCT, title: 'Product' },
    { key: ROUTES.COMPANY, title: 'About Us' },

    { key: ROUTES.WORKSPACE, title: 'Workspace' },
    { key: ROUTES.DEVELOPERS, title: 'Developers' },
    { key: ROUTES.INTEGRATIONS, title: 'Integrations' },
    { key: ROUTES.ACCOUNT, title: 'Account' }
  ],
  UNABLE_TO_CHANGE_ACCOUNT: '😥 Uh Oh! Something went wrong. Please try again later.',
  UNABLE_TO_UPDATE_WORKSPACE: 'Sorry! Not able to update the workspace, Please try again.'
}

export const ERROR_MESSAGES = {
  CHAT: {
    SEND_MESSAGE_ERROR: 'The message could not be sent.'
  },
  SHIPMENT: {
    UNABLE_TO_DELETE: 'Sorry! Not able to delete the shipment, Please try again later.'
  }
}
