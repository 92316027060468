// External Imports
import 'cross-fetch/polyfill'
import './index.css'

import { Confetti as KeychaineConfetti, useBreakpointValue, useColorMode } from '@chaine/keychaine'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'

import config from '../src/_server/config'
import { useRenderRoutes } from './_routes'
/** Import components */
import { DocumentTitle } from './_shared/components'
import constant from './_shared/constants/message'
import { useColorModeContext } from './_utils/color-mode-context'
import { useAuth } from './auth/data/use-auth'

declare global {
  interface Window {
    google?: unknown
  }
}

// Website Routes
const websiteRoutes = [
  '/company',
  '/carrier-visibility-onboarding',
  '/product/integrations',
  '/privacy',
  '/talk-to-sales',
  '/resources',
  '/resources/customer-stories',
  '/resources/testimonials',
  '/terms'
]

// Helper Functions
const setDocumentTitleIcon = (workspaceLogo: string | undefined) => {
  const link = document.createElement('link')
  const chaineIconLink = document.querySelector("link[rel*='icon']")
  link.id = 'favicon'
  link.rel = 'icon'
  link.type = 'image/x-icon'
  link.href = workspaceLogo || ''

  if (chaineIconLink) {
    document.head.removeChild(chaineIconLink)
  }
  document.head.appendChild(link)
}

const loadGoogleScript = () => {
  const script = document.createElement('script')
  script.src = `https://maps.googleapis.com/maps/api/js?key=${config.app.GOOGLE_API_KEY}&libraries=places&language=en`
  script.async = true
  script.defer = true
  document.body.appendChild(script)
}

export function App() {
  const [confetti, setConfetti] = useState(false)
  const [title, setTitle] = useState('Chaine')
  const [loaded, setLoaded] = useState(false)
  const location = useLocation()
  const { selectedWorkspace } = useAuth()
  const workspaceLogo = selectedWorkspace?.workspace?.logo

  DocumentTitle(title)

  const { colorMode: chakraColorMode, toggleColorMode } = useColorMode()
  const { colorMode, changeColorMode } = useColorModeContext()

  useEffect(() => {
    if (chakraColorMode !== colorMode) {
      toggleColorMode()
    }
  }, [colorMode, chakraColorMode, toggleColorMode])

  useEffect(() => {
    setDocumentTitleIcon(workspaceLogo)
  }, [workspaceLogo])

  useEffect(() => {
    const pathname = location.pathname.replace(/[^\w\s]/gi, '')
    const docTitle = constant.PAGE_TITLE.find((item) => {
      const key = item.key.replace(/[^\w\s]/gi, '')
      return key === pathname || pathname.includes(key)
    }) || { key: 'Default', title: 'Chaine' }
    if (docTitle?.title) setTitle(docTitle?.title)
  }, [location.pathname])

  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false })
  const shouldShowIntercom = !isMobile && import.meta.env.PROD
  const { boot, shutdown } = useIntercom()

  useEffect(() => {
    if (shouldShowIntercom) {
      boot({ actionColor: 'blue', hideDefaultLauncher: true })
    } else {
      shutdown()
    }
  }, [boot, shutdown, shouldShowIntercom])

  useEffect(() => {
    setLoaded(true)
    if (!window.google) loadGoogleScript()
  }, [])

  // Conditionally add overflow: auto based on route
  useEffect(() => {
    const isWebsiteRoute =
      websiteRoutes.some((route) => location.pathname.startsWith(route)) || location.pathname === '/'
    const isResponsive = window.innerWidth <= 992

    // Apply overflow logic based on screen size and route
    if (isResponsive || isWebsiteRoute) {
      document.body.style.overflow = 'auto'
    } else {
      document.body.style.overflow = 'hidden'
    }

    // Listen for screen resize to handle mobile/desktop transitions
    const handleResize = () => {
      if (window.innerWidth <= 992 || isWebsiteRoute) {
        document.body.style.overflow = 'auto'
      } else {
        document.body.style.overflow = 'hidden'
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      document.body.style.overflow = 'hidden' // Reset on unmount
    }
  }, [location.pathname])

  return (
    <>
      {confetti && loaded && <KeychaineConfetti variant={'apple'} onComplete={() => setConfetti(false)} />}
      <React.Suspense>{useRenderRoutes(setConfetti, changeColorMode)}</React.Suspense>
    </>
  )
}
