import { useLazyQuery } from '@apollo/client'
import {
  Avatar,
  AvatarBadge,
  Flex,
  GearIcon,
  IMenuItem,
  Menu,
  MenuIcon,
  PlusIcon,
  SignOutIcon,
  useColorModeValue as mode,
  useDisclosure,
  useMediaQuery
} from '@chaine/keychaine'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { ROUTES } from '../../../../_routes/routes-list'
import { GET_CONSTRAINT, GetConstraintReturnType } from '../../../../_shared/queries'
import { IWorkspace, MembershipAccessType } from '../../../../_shared/types'
import { useAnalytics } from '../../../../_utils/analytics-context'
import { authService } from '../../../../auth/data'
import { UsersWorkspace } from '../../../../auth/data/auth-types'
import { MeReturnType } from '../../../../auth/data/queries-mutations'
import { useAuth } from '../../../../auth/data/use-auth'
import { useChat } from '../../../../chat/context/chat-context'
import { GET_PUBLIC_WORKSPACE_MEMBERS, IWorkspaceMember } from '../../../../settings/members/data/queries'
import { SetupAndVerifyWorkspaceModal, useReplaceWorkspacenameInURL } from '../../../components'
import { AddCarrierToWorkspaceModal } from '../../../components/add-carrier-to-workspace-modal/add-carrier-to-workspace-modal'
import { Variant } from '../../../components/setup-workspace/types'

interface HeaderMenuProps {
  isMobile: boolean
  user: MeReturnType
}

/**
 * Displays the list of menu items
 */
export const HeaderMenu = React.memo(({ user, isMobile }: HeaderMenuProps) => {
  const navigate = useNavigate()
  const { disconnectUser } = useChat()
  const { onLogout } = useAuth()
  const { isFeatureEnabled, identifyWorkspace, identify } = useAnalytics()
  const [isSmallerThan760] = useMediaQuery('(max-width: 760px)')
  const { selectedWorkspace, setWorkspace } = useAuth()
  const [workspaceMembers, setWorkspaceMembers] = useState<IWorkspaceMember[]>([])
  const [constraintWorkspace, setConstraintWorkspace] = useState<IWorkspace>()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure()
  const { replaceWorkspacenameInURL } = useReplaceWorkspacenameInURL()

  const [searchParams] = useSearchParams()

  const carrierWorkspacename = searchParams.get('cw')

  /** Define query to check workspacename  */
  const [getWorkspaceMembers] = useLazyQuery<{ publicWorkspaceMembers: IWorkspaceMember[] }>(
    GET_PUBLIC_WORKSPACE_MEMBERS,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      onCompleted: (onCompletedData) => {
        const { publicWorkspaceMembers } = onCompletedData
        const members = publicWorkspaceMembers.filter(
          ({ user: userData, accessType, status }) =>
            status === 'Active' && (userData?.email != null || accessType !== MembershipAccessType.DRIVER)
        )

        setWorkspaceMembers(members)

        if (members.length > 0) onModalOpen()
      }
    }
  )

  /** Define query to check workspacename  */
  const [getConstraint] = useLazyQuery<{ getConstraint: GetConstraintReturnType }>(GET_CONSTRAINT, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onCompleted: (onCompletedData) => {
      const { getConstraint: constraint } = onCompletedData
      if (constraint) {
        setConstraintWorkspace(constraint.workspace)
        getWorkspaceMembers({
          context: {
            headers: {
              'ws-id': constraint.workspace?.id
            }
          }
        })
      }
    }
  })

  const [currentWorkspace, setCurrentWorkspace] = useState<{
    displayName: string
    id: string
    logo?: string
    workspacename: string
  }>({ displayName: '', id: '', logo: '', workspacename: '' })

  const { workspaces, refetchWorkspaces } = authService.getWorkspacesByUser()

  const hasWorkspaces = useMemo(() => {
    return workspaces && workspaces.length > 0
  }, [workspaces])

  const handleLogout = useCallback(async () => {
    onLogout()
    disconnectUser()
  }, [disconnectUser, onLogout])

  const switchWorkspace = useCallback(
    ({ workspace, ...rest }: UsersWorkspace) => {
      setCurrentWorkspace({
        displayName: workspace.displayName,
        id: workspace.id,
        logo: workspace?.logo,
        workspacename: workspace.workspacename
      })

      setWorkspace({ workspace, ...rest })
      replaceWorkspacenameInURL(workspace.workspacename)
    },
    [setWorkspace, replaceWorkspacenameInURL]
  )

  const menuItems = useMemo<IMenuItem[]>(() => {
    const items: IMenuItem[] = [
      {
        command: '⌃⌘S',
        icon: GearIcon,
        isVisible: isMobile,
        onClick: () => navigate(`/${ROUTES.ACCOUNT}/${ROUTES.PROFILE}`),
        textProperties: { text: 'Settings' }
      },
      {
        isDivider: true,
        isFullWidthDivider: true,
        isVisible: isMobile
      }
    ]

    if (hasWorkspaces) {
      // Sort workspaces by displayName
      const sortedWorkspaces =
        workspaces && workspaces.length > 0
          ? [...workspaces].sort((a, b) => a.workspace?.displayName?.localeCompare(b.workspace?.displayName))
          : workspaces

      sortedWorkspaces.forEach((workspace: UsersWorkspace, index: number) => {
        items.push({
          avatar: { name: workspace.workspace.displayName, src: workspace.workspace?.logo },
          command: `⌘${index}`,
          isChecked: workspace.workspace.id === currentWorkspace.id,
          onClick: () => switchWorkspace(workspace),
          textProperties: { text: workspace.workspace.displayName }
        })
      })

      if (!isFeatureEnabled('chaine-internal-user')) {
        items.push({
          isDivider: true,
          isFullWidthDivider: true,
          isVisible: isMobile
        })
      }
    }

    if (isFeatureEnabled('chaine-internal-user') || !hasWorkspaces) {
      items.push(
        {
          icon: PlusIcon,
          isVisible: isMobile,
          onClick: onOpen,
          textProperties: { text: 'Create new workspace' }
        },
        {
          isDivider: true,
          isFullWidthDivider: true,
          isVisible: isMobile
        }
      )
    }

    items.push({
      icon: SignOutIcon,
      onClick: handleLogout,
      textProperties: { text: 'Sign out' }
    })

    return items
  }, [
    isMobile,
    hasWorkspaces,
    isFeatureEnabled,
    handleLogout,
    navigate,
    workspaces,
    currentWorkspace.id,
    switchWorkspace,
    onOpen
  ])

  /**
   * Set the user's properties in the analytics system
   */
  useEffect(() => {
    if (user.id) {
      /** Identify the user in the analytics system using their unique ID */
      identify(user.id, {
        email: user.email || '',
        name: user.name || '',
        phone: user.phone || ''
      })
    }
  }, [user, identify])

  useEffect(() => {
    if (hasWorkspaces) {
      const selectedWorkspaceID = selectedWorkspace?.workspace?.id || workspaces[0]?.workspace?.id

      const isCarrierWorkspaceIDPresentInURL = workspaces.find(
        (ws) => ws.workspace.workspacename === carrierWorkspacename
      )

      if (!isCarrierWorkspaceIDPresentInURL) {
        if (carrierWorkspacename) getConstraint({ variables: { constraint: carrierWorkspacename } })
      } else setWorkspace(isCarrierWorkspaceIDPresentInURL)

      workspaces.forEach(({ workspace, ...rest }: UsersWorkspace) => {
        const workspaceID = isCarrierWorkspaceIDPresentInURL
          ? isCarrierWorkspaceIDPresentInURL.workspace.id
          : selectedWorkspaceID

        if (workspace.id === workspaceID) {
          if (!selectedWorkspace) setWorkspace({ workspace, ...rest })
          setCurrentWorkspace({
            displayName: workspace.displayName,
            id: workspace.id,
            logo: workspace?.logo,
            workspacename: workspace.workspacename
          })

          /** Set the workspace posthog event */
          identifyWorkspace(workspace)
        }
      })
    } else if (hasWorkspaces === false) {
      if (carrierWorkspacename) getConstraint({ variables: { constraint: carrierWorkspacename } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces, selectedWorkspace, hasWorkspaces])

  return (
    <>
      <Flex>
        <Menu
          suppressColorMode={true}
          menuIcon={<MenuIcon boxSize={8} color="gray.100" />}
          buttonAriaLabel={'Settings'}
          matchWidth={false}
          menuItems={menuItems}
        >
          <Flex
            borderWidth={!isMobile ? 1 : 0}
            borderColor={mode('gray.700', 'gray.600')}
            borderRadius={'md'}
            px={!isMobile ? 3 : 0}
            py={!isMobile ? 2 : 0}
            gap={4}
          >
            {!isMobile && hasWorkspaces && (
              <Avatar
                src={currentWorkspace.logo}
                name={currentWorkspace.displayName}
                size={isSmallerThan760 ? 'xs' : 'sm'}
                variant="square"
              />
            )}
            <Avatar
              src={user?.photo || ''}
              name={user?.name || ''}
              size={isSmallerThan760 ? 'xs' : 'sm'}
              variant="basic"
            >
              <AvatarBadge borderColor={'gray.900'} boxSize="1.25em" bg={'green.500'} />
            </Avatar>
          </Flex>
        </Menu>
      </Flex>

      {isOpen && (
        <SetupAndVerifyWorkspaceModal
          isOpen={isOpen}
          onClose={onClose}
          variant={Variant.CreateWorkspace}
          workspace={null}
          onWorkspaceCreation={setWorkspace}
          refetchWorkspaces={refetchWorkspaces}
          replaceWorkspacenameInURL={replaceWorkspacenameInURL}
        />
      )}

      {isModalOpen && (
        <AddCarrierToWorkspaceModal
          isOpen={isModalOpen}
          constraintWorkspace={constraintWorkspace}
          onClose={onModalClose}
          workspaceMembers={workspaceMembers}
        />
      )}
    </>
  )
})

HeaderMenu.displayName = 'HeaderMenu'
