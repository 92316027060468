/**
 *--------------- AUTHENTICATION EVENTS ---------------
 */

/**
 * Event triggered when verification code is entered
 */
export const verificationCodeEntered = 'verificationCodeEntered'

/**
 * Event triggered when a user enters in their email or phone number to begin the signup or login process
 */
export const signupLoginEmailPhoneEntered = 'signupLoginEmailPhoneEntered'

/**
 * Event triggered when a user enters in their name during the onboarding process
 */
export const userNameEntered = 'userNameEntered'

/**
 * Event triggered when a user selects their profile picture during the onboarding process
 */
export const profilePictureUploaded = 'profilePictureUploaded'

/**
 *--------------- POST EVENTS ---------------
 */

/**
 * Event triggered when a user edits their profile on the user page or post page
 */
export const editProfileButtonClicked = 'editProfileButtonClicked'

/**
 * Event triggered when a user clicks the button on the user page to message a user
 */
export const messageUserButtonClicked = 'messageUserButtonClicked'

/**
 * Event triggered when a user clicks the button on the post page to make an offer
 */
export const makeAnOfferButtonClicked = 'makeAnOfferButtonClicked'

/**
 * Event triggered when a user clicks on a post
 */
export const postClicked = 'postClicked'

/**
 * Event triggered when a user creates a post
 */
export const postCreated = 'postCreated'

/**
 * Event triggered when a user edits a post
 */
export const postEdited = 'postEdited'

/**
 * Event triggered when a user clicks on the view loadboard button and modal opens
 */
export const loadboardOpened = 'loadboardOpened'

/**
 * Event triggered once a user submits an offer
 */
export const postOfferSubmitted = 'postOfferSubmitted'

/**
 * Event triggered when a user submits additional qualification details related to an offer.
 */
export const postOfferQualificationDetailsSubmitted = 'postOfferQualificationDetailsSubmitted'

/**
 *--------------- SHIPMENT EVENTS ---------------
 */

/**
 * Event triggered when a user adds a stop in the shipment
 */
export const stopAdded = 'stopAdded'

/**
 * Event triggered when a user adds a stop ETA in the shipment
 */
export const stopETAUpdated = 'stopETAUpdated'

/**
 * Event triggered when a user removes a stop from the shipment
 */
export const stopRemoved = 'stopRemoved'

/**
 * Event triggered when someone clicks on the follow button to follow a user
 */
export const userFollowed = 'userFollowed'

/**
 *--------------- WORKSPACE EVENTS ---------------
 */

/**
 * Event triggered once a user verifies the workspace.
 */
export const workspaceVerified = 'workspaceVerified'

/**
 *--------------- ALERT EVENTS ---------------
 */

/**
 * Event triggered when a user dismisses the invite your network alert
 */
export const inviteNetworkAlertDismissed = 'inviteNetworkAlertDismissed'

/**
 * Event triggered when a user invites someone from their network
 */
export const invitationSent = 'invitationSent'

/**
 *--------------- NETWORK EVENTS ---------------
 */
/**
 * Event triggered when a user invites someone from their network
 */
export const emailSentToNetwork = 'emailSentToNetwork'
