export const ROUTES = {
  /** Account settings page urls */
  ACCOUNT: 'account',

  APP_DOWNLOAD_CONFIGURATION: 'app-download-configuration',

  APPS: 'apps',

  ASSIGNED_TO_ME: '/assigned-to-me',

  AUTOPILOT: 'autopilot',

  /** Billing urls */
  BILLING: 'billing',

  BILLING_CANCEL_PLAN: '/cancel-plan',

  BILLING_CARD_DETAILS: '/card-details',

  BILLING_INVOICE: '/invoice',

  BILLING_PLAN: '/plan',

  COMPANIES: '/companies',

  COMPANY: 'company',

  CONTAINERS: 'container-tracking',

  CREATE_ACCOUNT: '/create-account',

  DEVELOPERS: 'developers',

  DIRECT_MESSAGES: 'direct-messages',

  /** Carrier or Broker Details Page url */
  DOT: 'dot',

  HOME: '/assigned-to-me',

  /** General urls */
  HOME_PAGE: '/home',

  /** Inbox page urls */
  INBOX: '/inbox',

  INTEGRATIONS: 'integrations',

  /** Invitation urls */
  INVITE_USER: '/invite',

  JOIN_THE_NETWORK: '/carrier-visibility-onboarding',

  // Auth Page Section
  LOGIN: '/login',

  LOGS: 'logs',

  MEMBERS: 'members',

  MY_POSTS: '/my-posts',

  NETWORK: 'network',

  NOTIFICATIONS: 'notifications',

  OFFER_CHATS: 'offer-chats',

  /** Booking section urls */
  OFFERS: 'offers',

  OFFERS_EMAIL_CONFIGURATION: 'offers-email-configuration',

  /** Onboarding urls */
  ONBOARDING: '/onboarding',

  ONBOARDING_PROFILE_PICTURE: '/onboarding-profile-picture',

  POST: 'post',

  /** Post urls */
  POSTINGS: 'postings',

  POSTS: 'posts',

  PRIVACY: 'privacy',

  PRODUCT: 'product',

  PRODUCT_INTEGRATION: 'product/integrations',

  PROFILE: 'profile',

  PUBLIC_LOG: 'share/log',

  PUBLIC_SHIPMENT_TRACKING: 'share/shipment/tracking',

  REQUEST_ACCESS: 'request-access',

  /** Workspace urls */
  SEARCH: 'search',

  SHIPMENT_CHATS: 'shipment-chats',

  SHIPMENTS: 'shipments',
  SIGNUP: '/signup',

  TEAM_MEMBER: 'team-member',

  /** Website urls */
  TERMS: 'terms',

  TESTIMONIALS: 'testimonials',

  USAGE: 'reports',

  VERIFY: '/verify',

  VERIFY_INVITATION: '/verify-invitation',

  VOICE_BOT: 'voice-bot',

  VOICE_BOT_REPORTS: 'voice-bot-reports',

  WORKSPACE: 'workspace',
  WORKSPACES: 'workspaces'
}
