import {
  Box,
  CheckCircleIcon,
  ContainerIcon,
  Divider,
  Flex,
  HStack,
  LocationIcon,
  MailIcon,
  MenuIcon,
  MenuIconExpanded,
  octicons,
  Stack,
  useColorModeValue as mode,
  useMediaQuery
} from '@chaine/keychaine'
import React, { useEffect, useState } from 'react'

import { ROUTES } from '../../../_routes/routes-list'
import { useAnalytics } from '../../../_utils/analytics-context'
import { useAuth } from '../../../auth/data/use-auth'
import { NavGroup } from './nav-group'
import { NavLink } from './nav-link'

const containerWorkspaceFeatureFlagArray = ['f783f3e4-a722-4b71-8685-a24b56a95f75']

interface SideBarMenuItem {
  icon: octicons.Icon
  name: string
  path: string
}
interface SideBarMenu {
  general: SideBarMenuItem[]
  workspace: SideBarMenuItem[]
}

export const SideMenu = () => {
  const { selectedWorkspace } = useAuth()
  const { isFeatureEnabled } = useAnalytics()

  const [generalMenu] = useState<SideBarMenu['general']>([
    {
      icon: CheckCircleIcon,
      name: 'Assigned to me',
      path: ROUTES.ASSIGNED_TO_ME
    },
    {
      icon: MailIcon,
      name: 'Inbox',
      path: ROUTES.INBOX
    }
  ])

  const [workspaceMenu, setWorkspaceMenu] = useState<SideBarMenu['workspace']>([])

  useEffect(() => {
    if (selectedWorkspace?.workspace?.workspacename) {
      const activeWorkspacename = selectedWorkspace.workspace.workspacename
      const activeWorkspaceID = selectedWorkspace.workspace.id

      const workspaceMenuItems = [
        {
          icon: LocationIcon,
          name: 'Load Tracking',
          path: `/${activeWorkspacename}/${ROUTES.SHIPMENTS}`
        }
      ]

      if (isFeatureEnabled('chaine-internal-user') || containerWorkspaceFeatureFlagArray.includes(activeWorkspaceID)) {
        workspaceMenuItems.push({
          icon: ContainerIcon,
          name: 'Container Tracking',
          path: `/${activeWorkspacename}/${ROUTES.CONTAINERS}`
        })
      }

      setWorkspaceMenu(workspaceMenuItems)
    }
  }, [selectedWorkspace, isFeatureEnabled])

  const [isLargerThan480] = useMediaQuery('(min-width: 480px)')

  const [navSize, changeNavSize] = React.useState(`${isLargerThan480 ? 'expanded' : 'contracted'}`)
  const bg = mode('gray.50', 'gray.900')
  const color = mode('grayDark', 'gray.100')
  const toggleSideNav = () => {
    if (navSize == 'contracted') changeNavSize('expanded')
    else changeNavSize('contracted')
  }
  return (
    <Flex
      as="nav"
      direction="column"
      transition="all 1s"
      color={color}
      bg={bg}
      fontSize="0.875em"
      borderRightWidth="1px"
      p={4}
      height="100%"
      w="max-content"
      transitionDuration="fast"
    >
      <HStack h={8} justifyContent={navSize === 'expanded' ? 'right' : 'center'} pl={navSize === 'expanded' ? 1 : 0}>
        {navSize === 'expanded' ? (
          <>
            <Box onClick={toggleSideNav} _hover={{ cursor: 'pointer' }}>
              <MenuIconExpanded
                boxSize={8}
                color={'gray.500'}
                _hover={{ bg: mode('gray.100', 'whiteAlpha.200'), borderRadius: 'base' }}
              />
            </Box>
          </>
        ) : (
          <Box onClick={toggleSideNav} _hover={{ cursor: 'pointer' }}>
            <MenuIcon
              boxSize={8}
              color={'gray.500'}
              _hover={{ bg: mode('gray.100', 'whiteAlpha.200'), borderRadius: 'base' }}
            />
          </Box>
        )}
      </HStack>

      <Stack mt={4} w="max-content">
        <NavGroup menuHeadingExpanded="" menuHeadingContracted="" navSize={navSize}>
          {generalMenu.map((link: SideBarMenuItem) => (
            <NavLink
              key={link.name}
              title={link.name}
              path={link.path}
              icon={link.icon}
              navSize={navSize}
              activeOnlyWhenExact={true}
            />
          ))}
        </NavGroup>

        {workspaceMenu.length > 0 && (
          <>
            <Divider />
            <NavGroup menuHeadingExpanded="Workspace" menuHeadingContracted="WS" navSize={navSize}>
              {workspaceMenu.map((link: SideBarMenuItem) => (
                <NavLink
                  key={link.name}
                  title={link.name}
                  path={link.path}
                  icon={link.icon}
                  navSize={navSize}
                  activeOnlyWhenExact={true}
                />
              ))}
            </NavGroup>
          </>
        )}
      </Stack>
    </Flex>
  )
}
