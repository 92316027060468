import {
  Box,
  CheckCircleFillIcon,
  CommentDiscussionIcon,
  Flex,
  GearIcon,
  PackageDependentsIcon,
  TagIcon,
  useColorModeValue as mode
} from '@chaine/keychaine'
import * as React from 'react'

import { ROUTES } from '../../../../_routes/routes-list'
import { authService } from '../../../../auth/data'
import { useAuth } from '../../../../auth/data/use-auth'
import { CompanyType } from '../../../components/setup-workspace/types'
import { NavAction } from './nav-action'

const DesktopFooter = () => {
  return <footer>© 2021 Copyright. All rights reserved.</footer>
}

const MobileFooter = () => {
  const { selectedWorkspace, setWorkspace } = useAuth()
  const { workspaces } = authService.getWorkspacesByUser()
  const footerBG = mode('white', 'gray.900')

  React.useEffect(() => {
    if (!selectedWorkspace) {
      const hasWorkspaces = workspaces && workspaces.length > 0 ? true : false
      if (hasWorkspaces) setWorkspace(workspaces[0])
    }
  }, [selectedWorkspace, setWorkspace, workspaces])

  const activeWorkspace = React.useMemo(() => selectedWorkspace?.workspace?.workspacename, [selectedWorkspace])

  const footerItems = React.useMemo(() => {
    return workspaces?.length && selectedWorkspace && selectedWorkspace.workspace.workspaceType === CompanyType.Broker
      ? [
          {
            icon: PackageDependentsIcon,
            label: 'Tracking',
            path: `/${activeWorkspace}/${ROUTES.SHIPMENTS}`
          },
          {
            icon: TagIcon,
            label: 'Booking',
            path: `/${activeWorkspace}/${ROUTES.POSTS}`
          },
          {
            icon: CommentDiscussionIcon,
            label: 'Inbox',
            path: ROUTES.INBOX
          },
          {
            icon: CheckCircleFillIcon,
            label: 'Assigned',
            path: ROUTES.ASSIGNED_TO_ME
          },
          {
            icon: GearIcon,
            label: 'Settings',
            parentPath: ROUTES.ACCOUNT,
            path: ROUTES.ACCOUNT
          }
        ]
      : [
          {
            icon: CheckCircleFillIcon,
            label: 'Assigned',
            path: ROUTES.ASSIGNED_TO_ME
          },
          {
            icon: PackageDependentsIcon,
            label: 'Tracking',
            path: `/${activeWorkspace}/${ROUTES.SHIPMENTS}`
          },
          {
            icon: CommentDiscussionIcon,
            label: 'Inbox',
            path: ROUTES.INBOX
          },
          {
            icon: GearIcon,
            label: 'Settings',
            parentPath: ROUTES.ACCOUNT,
            path: ROUTES.ACCOUNT
          }
        ]
  }, [activeWorkspace, selectedWorkspace, workspaces])
  return (
    <Box
      width="full"
      borderTopWidth={0.25}
      display={{ lg: 'none' }}
      justifyContent="end"
      marginBottom="0"
      position="relative"
    >
      <Flex
        px="2"
        paddingTop={2}
        justifyContent="space-around"
        position="fixed"
        bottom="0"
        minH="max-content"
        bg={footerBG}
        w="full"
      >
        {footerItems.map((item) => (
          <NavAction.Mobile {...item} key={item.label} />
        ))}
      </Flex>
    </Box>
  )
}

export const Footer = {
  Desktop: DesktopFooter,
  Mobile: MobileFooter
}
