import { Avatar, Box, Circle, Flex, Heading, Skeleton, Text, useColorModeValue as mode } from '@chaine/keychaine'
import { startCase, toLower } from 'lodash'
import React, { Fragment, useContext } from 'react'

import { VerifiedIcon } from '../../../_assets/icons'
import { DataContext } from '../../../company'

type TUserDetailsProps = {
  url?: string
}

type TSubContent = {
  fontWeight?: string
  id: string | number
  item: React.ReactNode
}

// enum StatusEnum {
//   'close' = 'Close',
//   'open' = 'Open'
// }

/**
 * This component displays the basic details of company
 */
export function CompanyDetailsHeader({ url }: TUserDetailsProps) {
  const { companyData, isCompanyDataLoading } = useContext(DataContext)
  // const openStatusColor = mode('green.400', 'green.300')
  const textColor = mode('text.secondary', 'text.tertiary')

  // const companyStatusHeadings: TSubContent[] = [
  //   {
  //     id: 'subContent-1',
  //     item: (
  //       <Flex alignItems="center" gap={1}>
  //         <Box color="blue.400">
  //           <Icon as={LinkIcon} boxSize={4} />
  //         </Box>
  //         In network
  //       </Flex>
  //     )
  //   },
  //   {
  //     id: 'subContent-2',
  //     item: (
  //       <Flex alignItems="center" gap={1}>
  //         <Box color="green.300">
  //           <Icon as={CheckIcon} boxSize={4} />
  //         </Box>
  //         Approved
  //       </Flex>
  //     )
  //   }
  // ]

  const companyDetailsHeadings: TSubContent[] = [
    {
      fontWeight: 'semilight',
      id: 1,
      item: isCompanyDataLoading ? <Skeleton width={20} height={6} /> : <Text color={textColor}>{'Carrier'}</Text>
    },
    {
      fontWeight: 'semilight',
      id: 2,
      item: isCompanyDataLoading ? (
        <Skeleton width={20} height={6} />
      ) : (
        <Text color={textColor}>{`MC#${' ' + (companyData?.mcNumber || '--')}`}</Text>
      )
    },
    {
      fontWeight: 'semilight',
      id: 3,
      item: isCompanyDataLoading ? (
        <Skeleton width={20} height={6} />
      ) : (
        <Text color={textColor}>{`DOT# ${companyData?.id || '--'}`}</Text>
      )
    }
  ]

  return (
    <Flex gap={2}>
      <Avatar
        variant="square"
        src={url}
        size="xl"
        name={startCase(toLower(companyData?.legalName?.oldestValue?.value))}
        getInitials={() => startCase(toLower(companyData?.legalName?.oldestValue?.value)).trim().charAt(0)}
      />
      <Box px={2} py={1}>
        <Flex alignItems="center" gap={2}>
          <Heading as="h1" size={{ base: 'sm', lg: 'lg', md: 'md' }} fontWeight="semibold" color="text.lig" gap={2}>
            {isCompanyDataLoading ? (
              <Skeleton width="sm" height={8} />
            ) : (
              startCase(toLower(companyData?.legalName?.oldestValue?.value)) || '--'
            )}
          </Heading>
          <VerifiedIcon width={6} height={6} />
        </Flex>
        {/* <SubHeadingContent contents={companyStatusHeadings} contentCount={companyStatusHeadings.length} /> */}
        <SubHeadingContent contents={companyDetailsHeadings} contentCount={companyDetailsHeadings.length} />
        {/* <Heading
          as="h5"
          mt={2}
          fontSize={{ base: 'sm', lg: 'lg', md: 'md' }}
          fontWeight="bold"
          color={openStatusColor}
          alignItems="center"
          gap={2}
        >
          {StatusEnum[companyDetails.status]}
        </Heading> */}
      </Box>
    </Flex>
  )
}

function SubHeadingContent({ contents, contentCount }: { contentCount: number; contents: TSubContent[] }) {
  return (
    <Flex alignItems="center" gap={3} mt={2}>
      {contents?.map((content: TSubContent, id: number) => (
        <Fragment key={content.id}>
          <Heading
            as="h5"
            fontSize={{ base: 'sm', lg: 'lg', md: 'md' }}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            gap={1}
            fontWeight={content.fontWeight || 'semibold'}
          >
            {content.item}
          </Heading>
          {id + 1 < contentCount ? <Circle size={1} bg="icon.secondary" mr={0.5} ml={0.5} /> : null}
        </Fragment>
      ))}
    </Flex>
  )
}
