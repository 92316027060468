import { TShipmentStatus } from '.'
import { UUID } from './data-types'
import { TTruckType } from './equipment'
import { IUpdatedBy } from './shipment'
import { IPublicStop } from './stop'
import { ChaineWeightType } from './weight'
import { IWorkspace } from './workspace'

export interface IChatUser {
  banned?: boolean
  email: string | null
  name: string | null
  online?: boolean
  phone: string | null
  photo?: string | null
  role?: string | null
  userID: UUID
  /**
   * The workspace ID of the user as it is listed for that specific chat channel when they sent the message
   */
  workspaceID?: UUID
}

export interface IChatMember extends IChatUser {
  channelRole: string | null
  isUserInChannelOwnerWorkspace?: boolean
  userRoleInShipmentChannel?: ShipmentChannelRoleEnum
  /**
   * The workspace ID of the member if they were added to the channel and a workspace was specified
   */
  workspaceID?: UUID
}

/**
 * The user who sent a shipment chat message (to be used for assistance requested, exception detected events)
 */
export interface IShipmentChatUser extends IChatUser {
  isUserInChannelOwnerWorkspace: boolean
  userRoleInShipmentChannel: ShipmentChannelRoleEnum
}

export enum ChaineChannelTypeEnum {
  Default = 'Default',
  Driver = 'Driver',
  Offer = 'Offer',
  Shipment = 'Shipment'
}

export interface IShipmentChatMetadata {
  chaineChannelType: ChaineChannelTypeEnum
  destinationCity?: string
  destinationState?: string
  originCity?: string
  originState?: string
  shipmentID: string
  shipmentNumber?: string
  shipmentOwnerEnterpriseWorkspaceID?: UUID
  shipmentOwnerWorkspaceID?: UUID
  shipmentStatus?: TShipmentStatus
  shipmentStatusTime?: string
  updatedBy?: IUpdatedBy
  /**
   * @deprecated Use shipmentOwnerWorkspaceID instead
   */
  workspaceID?: UUID
}

export interface IPostChatMetadata {
  commodity: string | null
  id: string
  loadNumber: string | null
  stops: IPublicStop[] | null
  truckType: TTruckType[] | null
  weight: ChaineWeightType | null
  workspace: IWorkspace
}

export interface IPostOfferChatMetadata {
  offerID: string
  offeredBy: string
  post: IPostChatMetadata
  postOwnerEnterpriseWorkspaceID?: UUID
  postOwnerWorkspaceID: UUID
}

export enum AttachmentTypeEnum {
  Audio = 'audio',
  File = 'file',
  Image = 'image',
  Text = 'text',
  Video = 'video'
}

/**
 * This is the custom message type, which is used to identify the type of message on the client side.
 * 'Default' is a regular message
 * 'Event' is a message that is an event, and it will require an 'actionType'. For example, if a shipment is created, then the message type will be 'Event', and the message will state "{user.name} created the shipment". If messageType is 'Event', then the 'actionType' is required.
 * 'Template' us when you provide a message type of "HTML" or "Markdown". This is used by the client.
 *
 */
export enum SendChatMessageMessageTypeEnum {
  Default = 'default',
  Event = 'event',
  Template = 'template'
}

/**
 * This is required if messageType = 'Event'
 * This is the custom event type of the message, which is used on client side to identify the event message. Currently, we are using this for "System" messages when the 'from' is Chaine.
 */
export enum SendChatMessageEventEnum {
  AppDownloadMessageCancelled = 'appDownloadMessageCancelled',
  AppDownloadMessageCompleted = 'appDownloadMessageCompleted',

  AppDownloadMessageScheduled = 'appDownloadMessageScheduled',
  DemoEvent = 'demoEvent',
  MemberAdded = 'memberAdded',
  MemberRemoved = 'memberRemoved',
  PostOfferCreated = 'postOfferCreated',
  ShipmentCreated = 'shipmentCreated',
  ShipmentExceptionDetected = 'shipmentExceptionDetected',
  ShipmentRuleScheduled = 'shipmentRuleScheduled',
  ShipmentUpdated = 'shipmentUpdated',
  StopArrived = 'stopArrived',
  StopDeparted = 'stopDeparted',
  StopETAUpdated = 'stopETAUpdated'
}

export interface IAttachment {
  assetURL: string
  fileSize: number
  filename: string
  mimeType: string
  thumbURL?: string
  type: AttachmentTypeEnum
}

export interface IChatMessage {
  actionType: string | null
  attachments?: IAttachment[]
  createdAt: string | null
  html: string | null
  text: string | null
  type: string | null
  updatedAt: string | null
}

/**
 * The enum to indicate where the chat message event is sourced from
 */
export enum ChatMessageEventSourceEnum {
  AIGenerated = 'AI Generated',
  ChaineWebApp = 'Chaine Web App',
  Copilot = 'Copilot',
  DriverApp = 'Driver App',
  Email = 'Email',
  SMS = 'SMS',
  TMS = 'TMS',
  Unknown = 'Unknown'
}

/**
 * The enum to indicate who generated the chat message
 */
export enum ChatMessageGeneratedByEnum {
  Copilot = 'Copilot',
  GenAI = 'GenAI',
  Human = 'Human'
}

/**
 * The metadata for a Copilot rule and related information when the message is generated by Copilot
 */
export interface IChatMessageCopilotMetadata {
  ruleDescription: string
  ruleName: string
}

/**
 * The sender's role in the channel (e.g. 'Driver')
 */
export enum ShipmentChannelRoleEnum {
  Chain = 'Chain',
  Dispatcher = 'Dispatcher',
  Driver = 'Driver',
  TeamMember = 'Team Member',
  Unknown = 'Unknown'
}
