import React from 'react'

import { createGenericContext } from '../../../_utils/create-generic-context'
import { IRuleConfigurationReturnType, IRuleTemplateReturnType } from '../data'
import { ActionTypesUnion, ConditionsSelection, IConditions, TriggerTypesUnion } from '../types'

// Interface for the context value
interface CopilotContextValue {
  handleRuleDescriptionChange: (ruleDescription: string) => void
  handleRuleNameChange: (ruleName: string) => void
  handleSetSelectedActions: (actions: ActionTypesUnion[] | null) => void
  handleSetSelectedCondition: (condition: ConditionsSelection | null) => void,
  handleSetSelectedConditions: (conditions: IConditions | null) => void,
  handleSetSelectedRule: (rule: Partial<IRuleTemplateReturnType> | Partial<IRuleConfigurationReturnType> | null) => void,
  handleSetSelectedTrigger: (trigger: TriggerTypesUnion | null) => void,
  selectedCondition: ConditionsSelection | null
  selectedRule: Partial<IRuleTemplateReturnType> | Partial<IRuleConfigurationReturnType> | null
}

const [useCopilotContext, CopilotContextProvider] = createGenericContext<CopilotContextValue>(
  'useColorModeContext must be used within the ColorModeContextProvider'
)

// Create the provider
const CopilotProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [selectedRule, setSelectedRule] = React.useState<
    Partial<IRuleTemplateReturnType> | Partial<IRuleConfigurationReturnType> | null
  >(null)
  const [selectedCondition, setSelectedCondition] = React.useState<ConditionsSelection | null>(null)

  const value: CopilotContextValue = {
    handleRuleDescriptionChange: (ruleDescription) => {
      setSelectedRule({ ...selectedRule, description: ruleDescription })
    },
    handleRuleNameChange: (ruleName) => {
      setSelectedRule({ ...selectedRule, name: ruleName })
    },
    handleSetSelectedActions: (actions) => {
      setSelectedRule({ ...selectedRule, actions: actions || undefined })
    },
    handleSetSelectedCondition: (condition) => {
      setSelectedCondition(condition)
    },
    handleSetSelectedConditions: (conditions) => {
      setSelectedRule({ ...selectedRule, conditions: conditions || undefined })
    },
    handleSetSelectedRule: (rule) => {
      setSelectedRule(rule)
    },
    handleSetSelectedTrigger: (trigger) => {
      setSelectedRule({ ...selectedRule, trigger: trigger || undefined })
    },
    selectedCondition: selectedCondition,
    selectedRule: selectedRule
  }

  return <CopilotContextProvider value={value}>{children}</CopilotContextProvider>
}

export { CopilotProvider, useCopilotContext }
