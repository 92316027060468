import { ColorModeScript, KeychaineProvider, theme } from '@chaine/keychaine'
import React from 'react'

import SafeAreaInsetsProvider from './_shared/layouts/safe-area-context'
import { AnalyticsProvider } from './_utils/analytics-context'
import { useColorModeContext } from './_utils/color-mode-context'
import { ChatProvider } from './chat/context/chat-context'

// Providers Component
export function Providers({ children }: { children: React.ReactNode }) {
  const { currentThemeObject } = useColorModeContext()
  const calculatedTheme = { ...theme, colors: { ...currentThemeObject } }

  return (
    <SafeAreaInsetsProvider>
      <AnalyticsProvider>
        <ChatProvider>
          <KeychaineProvider theme={calculatedTheme}>
            <ColorModeScript />
            {children}
          </KeychaineProvider>
        </ChatProvider>
      </AnalyticsProvider>
    </SafeAreaInsetsProvider>
  )
}
