import validateEmail from 'validator/lib/isEmail'

/* eslint-disable */
/** eslint is disabled due to regex. */
export class StringUtils {
  /**
   * Capitalize first letter.
   * @param {String} name
   */
  static capitalizeFirstLetter(name: string): string {
    return name.charAt(0).toUpperCase() + name.slice(1)
  }

  /**
   * Checks if a string is an email or not
   * @param {String} emailOrPhone
   */
  static isEmail(emailOrPhone: string): boolean {
    return validateEmail(emailOrPhone)
  }

  /**
   * Checks if a string is a phone number.
   * @param {String} phoneNumber
   */
  static isPhone(phoneNumber: string): boolean {
    const REGEX = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
    return REGEX.test(phoneNumber)
  }

  /**
   * Format the currency amount
   *
   * @param value string
   * @param decimal After how many digits do we want to show
   * @returns string
   */
  static currencyFormatter(value: string, decimal: number): string {
    return parseFloat(parseFloat(value).toFixed(decimal)).toLocaleString()
  }

  /**
   * Format the weight
   *
   * @param weight number
   * @param unit unit of weight
   * @returns string
   */
  static formatWeight(weight: number, unit: string, locale = 'en-US') {
    const hasDecimalValue = weight % 1 !== 0
    const formatter = new Intl.NumberFormat(locale, {
      style: 'decimal',
      minimumFractionDigits: hasDecimalValue ? 1 : 0,
      maximumFractionDigits: hasDecimalValue ? 2 : 0,
      useGrouping: true // Enable comma separators for every thousand
    })

    return `${formatter.format(weight)} ${unit}`
  }

  /**
   * Format the distance
   *
   * @param distance number
   * @param unit unit of distance
   * @returns string
   */
  static formatDistance(distance: string, unit: string, locale = 'en-US') {
    const formattedDistance =
      parseFloat(distance).toLocaleString(locale, { style: 'decimal', maximumFractionDigits: 2 }) + ' ' + unit

    return formattedDistance
  }

  static formatTemperature(params: {
    max: number | null
    min: number | null
    unit: string | null
    runType: string | null
  }) {
    const { max, min, unit = 'F', runType } = params

    if (max === null && min === null) {
      return null
    }

    if (max === null && min) {
      return `${parseFloat(min.toString())}° ${unit || 'F'}${runType && runType !== 'false' ? ' ' + runType : ''}`
    }

    if (min === null && max) {
      return `${parseFloat(max.toString())}° ${unit || 'F'}${runType && runType !== 'false' ? ' ' + runType : ''}`
    }

    if (max === min && max && min) {
      return `${parseFloat(max.toString())}° ${unit || 'F'}${runType && runType !== 'false' ? ' ' + runType : ''}`
    }

    if (max && min) {
      return `${parseFloat(min.toString())}° to ${parseFloat(max.toString())}° ${unit || 'F'}${
        runType && runType !== 'false' ? ' ' + runType : ''
      }`
    }
  }

  static hasDuplicateEmails(emails: string[]): boolean {
    const emailSet = new Set() // Use a Set to store unique emails
    for (const email of emails) {
      if (emailSet.has(email)) {
        return true // Duplicate found
      }
      emailSet.add(email) // Add email to the Set
    }
    return false // No duplicates found
  }

  static companyNameToTitleCase(str: string) {
    return str
      .split(' ')
      .map((word) => {
        // Handle variations of 'Inc' and 'Inc.', only changing the case if it matches exactly.
        if (word.toLowerCase() === 'inc') {
          return 'Inc'
        }
        if (word.toLowerCase() === 'inc.') {
          return 'Inc.'
        }
        // Skip title casing if the word is already in lowercase
        if (word.length > 3 && /^[a-z]+$/.test(word)) {
          return word
        }

        // Skip title casing if the word has a mix of uppercase and lowercase letters
        if (/[a-z]/.test(word) && /[A-Z]/.test(word)) {
          return word
        }

        // Apply title casing to words with more than 3 characters that don't have special capitalization
        if (word.length > 3) {
          // Convert the first character to uppercase and the rest to lowercase
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        } else {
          // Leave words with 3 or fewer characters in their original case
          return word
        }
      })
      .join(' ')
      .replace(/- /g, ' - ') // Correct spacing around hyphens, if any, to maintain consistency
      .replace(/ - /g, ' - ') // Correct spacing around hyphens, if any, to maintain consistency
  }

  /**
   * Truncate the text to a certain limit and append '...' at the end
   * @param text The text to truncate
   * @param limit The number of characters to truncate the text to
   * @returns
   */
  static truncate(text: string, limit: number = 20): string {
    return text.length > limit ? text.slice(0, limit) + '...' : text
  }
}
