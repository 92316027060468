import React, { lazy, Suspense } from 'react'
import { Navigate } from 'react-router-dom'
import { useAnalytics } from 'src/_utils/analytics-context'
import { useAuth } from 'src/auth/data/use-auth'

import { AccountSettingsPage } from '../../settings'
import { CopilotProvider } from '../../settings/copilot/context/use-copilot-context'
import { RequireWorkspaceValidation } from '../hoc/protected-workspace-route'
import { lazyRetry } from '../lazy-retry'
import { ROUTES } from '../routes-list'

const DevelopersPage = lazy(() => lazyRetry(() => import('../../settings/developer/developer-page'), 'DevelopersPage'))
const LogsPage = lazy(() => lazyRetry(() => import('../../settings/logging/logs-page'), 'LogsPage'))
const IntegrationsPage = lazy(() =>
  lazyRetry(() => import('../../settings/integrations/integrations-page'), 'IntegrationsPage')
)
const MembersPage = lazy(() => lazyRetry(() => import('../../settings/members/members-page'), 'MembersPage'))
const NotificationsPage = lazy(() =>
  lazyRetry(() => import('../../settings/workspace-notifications/workspace-notifications-page'), 'NotificationsPage')
)
const UsagePage = lazy(() => lazyRetry(() => import('../../settings/reports/usage-page'), 'UsagePage'))
const UserProfilePage = lazy(() =>
  lazyRetry(() => import('../../settings/user-profile/user-profile-page'), 'UserProfilePage')
)
const WorkspacePage = lazy(() => lazyRetry(() => import('../../settings/workspace/workspace-page'), 'WorkspacePage'))
const Workspaces = lazy(() => lazyRetry(() => import('../../settings/workspace-list/workspace-page'), 'Workspaces'))
const AppsPage = lazy(() => lazyRetry(() => import('../../settings/apps/apps-page'), 'AppsPage'))
const CopilotPage = lazy(() => lazyRetry(() => import('../../settings/copilot/copilot-page'), 'CopilotPage'))
const OffersEmailConfigurationPage = lazy(() =>
  lazyRetry(() => import('../../settings/offers-email/offers-email-configuration-page'), 'EmailConfigurationPage')
)
const VoiceBotConfigurationPage = lazy(() =>
  lazyRetry(
    () => import('../../settings/voice-bot-configuration/voice-bot-configuration-page'),
    'VoiceBotConfigurationPage'
  )
)
const VoiceBotReportsPage = lazy(() =>
  lazyRetry(() => import('../../settings/voice-bot-reports/voice-bot-reports-page'), 'VoiceBotReportsPage')
)
const AppDownloadConfigurationPage = lazy(() =>
  lazyRetry(
    () => import('../../settings/app-download-configuration/app-download-configuration-page'),
    'AppDownloadConfigurationPage'
  )
)

// Specific workspaces for features
const greatLakesWorkspaceID = '15ac8580-742d-45db-ae04-3bb538928e01'
const magellanWorkspaceID = '7fde586f-e5ae-4d00-984d-022258ff5440'

export const myAccountSettingsRoutes = () => {
  return [
    {
      children: [
        { element: <Navigate to={ROUTES.PROFILE} />, index: true },
        {
          element: (
            <Suspense>
              <UserProfilePage />
            </Suspense>
          ),
          path: ROUTES.PROFILE
        },
        {
          element: (
            <Suspense>
              <Workspaces />
            </Suspense>
          ),
          path: ROUTES.WORKSPACES
        }
      ],
      element: <AccountSettingsPage />,
      path: ROUTES.ACCOUNT
    }
  ]
}

export const workspaceAccountSettingsRoutes = () => {
  //Check if the user is a chaine internal user or a specific workspace
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isFeatureEnabled } = useAnalytics()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { selectedWorkspace } = useAuth()
  const selectedWorkspaceID = selectedWorkspace?.workspace?.id

  const isChaineInternalUser = isFeatureEnabled('chaine-internal-user')
  const workspaceSpecificFeatureFlag =
    selectedWorkspaceID === greatLakesWorkspaceID || selectedWorkspaceID === magellanWorkspaceID

  const workspaceSettingsRoutes = [
    { element: <Navigate to={ROUTES.WORKSPACE} />, index: true },
    {
      element: (
        <Suspense>
          <WorkspacePage />
        </Suspense>
      ),
      path: ROUTES.WORKSPACE
    },
    {
      element: (
        <Suspense>
          <DevelopersPage />
        </Suspense>
      ),
      path: ROUTES.DEVELOPERS
    },
    {
      element: (
        <Suspense>
          <AppsPage />
        </Suspense>
      ),
      path: ROUTES.APPS
    },
    {
      element: (
        <Suspense>
          <CopilotProvider>
            <CopilotPage />
          </CopilotProvider>
        </Suspense>
      ),
      path: ROUTES.AUTOPILOT
    },
    {
      element: (
        <Suspense>
          <LogsPage />
        </Suspense>
      ),
      path: ROUTES.LOGS
    },
    {
      element: (
        <Suspense>
          <IntegrationsPage />
        </Suspense>
      ),
      path: ROUTES.INTEGRATIONS
    },
    {
      element: (
        <Suspense>
          <MembersPage />
        </Suspense>
      ),
      path: ROUTES.MEMBERS
    },
    {
      element: (
        <Suspense>
          <UsagePage />
        </Suspense>
      ),
      path: ROUTES.USAGE
    },
    {
      element: (
        <Suspense>
          <NotificationsPage />
        </Suspense>
      ),
      path: ROUTES.NOTIFICATIONS
    },
    {
      element: (
        <Suspense>
          <OffersEmailConfigurationPage />
        </Suspense>
      ),
      path: ROUTES.OFFERS_EMAIL_CONFIGURATION
    },
    {
      element: (
        <Suspense>
          <AppDownloadConfigurationPage />
        </Suspense>
      ),
      path: ROUTES.APP_DOWNLOAD_CONFIGURATION
    }
  ]

  // Add routes if the user is a chaine internal user, done in this method to avoid false or null values
  if (isChaineInternalUser || workspaceSpecificFeatureFlag) {
    workspaceSettingsRoutes.push({
      element: (
        <Suspense>
          <VoiceBotConfigurationPage />
        </Suspense>
      ),
      path: ROUTES.VOICE_BOT
    })
    workspaceSettingsRoutes.push({
      element: (
        <Suspense>
          <VoiceBotReportsPage />
        </Suspense>
      ),
      path: ROUTES.VOICE_BOT_REPORTS
    })
  }

  return [
    {
      children: workspaceSettingsRoutes,
      element: (
        <RequireWorkspaceValidation>
          <Suspense>
            <AccountSettingsPage />
          </Suspense>
        </RequireWorkspaceValidation>
      ),
      path: `/:workspace_name/${ROUTES.ACCOUNT}`
    }
  ]
}
