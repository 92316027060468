import { TShipmentStatus } from '.'
import { UUID } from './data-types'
import { TTruckType } from './equipment'
import { IPublicStop } from './stop'
import { ChaineWeightType } from './weight'
import { IWorkspace } from './workspace'

export interface IChatUser {
  banned?: boolean
  email: string | null
  name: string | null
  online?: boolean
  phone: string | null
  photo?: string | null
  role?: string | null
  userID: UUID
}

export interface IChatMember extends IChatUser {
  channelRole: string | null
  isUserInChannelOwnerWorkspace?: boolean
  userRoleInShipmentChannel?: ShipmentChannelRoleEnum
}

/**
 * The user who sent a shipment chat message (to be used for assistance requested, exception detected events)
 */
export interface IShipmentChatUser extends IChatUser {
  isUserInChannelOwnerWorkspace: boolean
  userRoleInShipmentChannel: ShipmentChannelRoleEnum
}

export enum ChaineChannelTypeEnum {
  Default = 'Default',
  Driver = 'Driver',
  Offer = 'Offer',
  Shipment = 'Shipment'
}

export interface IShipmentChatMetadata {
  chaineChannelType: ChaineChannelTypeEnum
  destinationCity?: string
  destinationState?: string
  originCity?: string
  originState?: string
  shipmentID: string
  shipmentNumber?: string
  shipmentOwnerEnterpriseWorkspaceID?: UUID
  shipmentOwnerWorkspaceID?: UUID
  shipmentStatus?: TShipmentStatus
  shipmentStatusTime?: string
  /**
   * @deprecated Use shipmentOwnerWorkspaceID instead
   */
  workspaceID?: UUID
}

export interface IPostChatMetadata {
  commodity: string | null
  id: string
  loadNumber: string | null
  stops: IPublicStop[] | null
  truckType: TTruckType[] | null
  weight: ChaineWeightType | null
  workspace: IWorkspace
}

export interface IPostOfferChatMetadata {
  offerID: string
  offeredBy: string
  post: IPostChatMetadata
  postOwnerWorkspaceID: string
}

export enum AttachmentTypeEnum {
  Audio = 'audio',
  File = 'file',
  Image = 'image',
  Text = 'text',
  Video = 'video'
}

export interface IAttachment {
  assetURL: string
  fileSize: number
  filename: string
  mimeType: string
  thumbURL?: string
  type: AttachmentTypeEnum
}

export interface IChatMessage {
  actionType: string | null
  attachments?: IAttachment[]
  createdAt: string | null
  html: string | null
  text: string | null
  type: string | null
  updatedAt: string | null
}

/**
 * The enum to indicate where the chat message event is sourced from
 */
export enum ChatMessageEventSourceEnum {
  AIGenerated = 'AI Generated',
  ChaineWebApp = 'Chaine Web App',
  Copilot = 'Copilot',
  DriverApp = 'Driver App',
  Email = 'Email',
  SMS = 'SMS',
  TMS = 'TMS'
}

/**
 * The enum to indicate who generated the chat message
 */
export enum ChatMessageGeneratedByEnum {
  Copilot = 'Copilot',
  GenAI = 'GenAI',
  Human = 'Human'
}

/**
 * The metadata for a Copilot rule and related information when the message is generated by Copilot
 */
export interface IChatMessageCopilotMetadata {
  ruleDescription: string
  ruleName: string
}

/**
 * The sender's role in the channel (e.g. 'Driver')
 */
export enum ShipmentChannelRoleEnum {
  Chain = 'Chain',
  Dispatcher = 'Dispatcher',
  Driver = 'Driver',
  TeamMember = 'Team Member',
  Unknown = 'Unknown'
}
