import React, { lazy, Suspense } from 'react'

import { ManageShipmentsLayout } from '../../manage-shipments/manage-shipments-layout'
import { RequireWorkspaceValidation } from '../hoc/protected-workspace-route'
import { lazyRetry } from '../lazy-retry'
import { ROUTES } from '../routes-list'

const WorkspaceShipmentsPage = lazy(() =>
  lazyRetry(() => import('../../manage-shipments/workspace-load-tracking-page'), 'WorkspaceShipmentsPage')
)
const WorkspaceContainerTrackingPage = lazy(() =>
  lazyRetry(() => import('../../manage-shipments/workspace-ocean-tracking-page'), 'WorkspaceContainerTrackingPage')
)
const ShipmentPage = lazy(() => lazyRetry(() => import('../../manage-shipments/shipment-page'), 'ShipmentPage'))
const AssignedToMePage = lazy(() =>
  lazyRetry(() => import('../../manage-shipments/assigned-to-me-page'), 'AssignedToMePage')
)

export const shipmentRoutes = () => {
  return [
    {
      children: [
        {
          element: (
            <Suspense>
              <AssignedToMePage />
            </Suspense>
          ),
          path: ROUTES.ASSIGNED_TO_ME
        },
        {
          element: (
            <RequireWorkspaceValidation>
              <Suspense>
                <WorkspaceShipmentsPage />
              </Suspense>
            </RequireWorkspaceValidation>
          ),
          path: `/:workspace_name/${ROUTES.SHIPMENTS}`
        },
        {
          element: (
            <RequireWorkspaceValidation>
              <Suspense>
                <WorkspaceContainerTrackingPage />
              </Suspense>
            </RequireWorkspaceValidation>
          ),
          path: `/:workspace_name/${ROUTES.CONTAINERS}`
        },
        {
          element: (
            <RequireWorkspaceValidation>
              <Suspense>
                <ShipmentPage />
              </Suspense>
            </RequireWorkspaceValidation>
          ),
          path: `/:workspace_name/${ROUTES.SHIPMENTS}/:shipment_id`
        },
        {
          element: (
            <Suspense>
              <ShipmentPage />
            </Suspense>
          ),
          path: `/${ROUTES.ASSIGNED_TO_ME}/${ROUTES.SHIPMENTS}/:shipment_id`
        }
      ],
      element: <ManageShipmentsLayout />
    }
  ]
}
